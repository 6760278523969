import {
  Button,
  Drawer,
  Grid,
  Menu,
  type MenuProps,
  styled,
  MenuItem,
  Card,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  type ListItemButtonProps,
  Box
} from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import { type IUserData } from '../../../types'
import { useAppStore } from '../../../storage'
import { ROUTES } from '../../../app.router/router.constants'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { BACKEND_URL } from '../../../constants/api.constants'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({}))

const StyledListItemButton = styled((props: ListItemButtonProps) => (
  <ListItemButton
    sx={{
      // '&$selected': {
      //   backgroundColor: 'red',
      //   '&:hover': {
      //     backgroundColor: 'orange'
      //   }
      // }
      borderRadius: '8px',
      '&.Mui-selected': {
        '.icon_box': {
          backgroundColor: 'rgba(19, 27, 74, 0) !important'
        },
        backgroundImage:
          'linear-gradient(to right, rgba(25, 25, 87, 1) , rgba(58, 53, 197, 1))'
      },
      '&:hover': {
        backgroundImage:
          'linear-gradient(to right, rgba(25, 25, 87, 0.5) , rgba(58, 53, 197, 0.5))',
        '.icon_box': {
          backgroundColor: 'rgba(19, 27, 74, 0) !important'
        }
      }
    }}
    {...props}
  />
))(({ theme }) => ({}))

interface LayoutProps {
  user: IUserData
}

const DashboardDrawer: React.FC<LayoutProps> = ({ user }) => {
  const drawerVariant = useMediaQuery('(min-width:800px)')
  const { activeDashboardTab, drawerOpen, cardsData } = useAppStore()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<null | number>(
    null
  )
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleLogOut = (): void => {
    localStorage.removeItem('userToken')
    location.reload()
  }
  const handleProfileClick = (): void => {
    navigate(ROUTES.User)
  }

  const handleMenuItemClick = (index: number): void => {
    // TODO handleMenuItemClick
    setSelectedMenuItem(index)
    useAppStore.setState({ activeDashboardTab: index, drawerOpen: false })
  }

  React.useEffect(() => {
    if (activeDashboardTab !== selectedMenuItem) {
      setSelectedMenuItem(activeDashboardTab)
    }
  }, [activeDashboardTab])
  return (
    <Drawer
      className={styles.drawer}
      sx={{
        width: '20vw',
        // flexShrink: 0
        '& .MuiDrawer-docked': {
          zIndex: '500'
        },
        '& .MuiDrawer-paper': {
          width: '20vw',
          boxSizing: 'border-box',
          background: 'linear-gradient(rgba(9, 12, 43, 1), rgba(14, 18, 62, 1))'
        },
        '@media screen and (max-width: 800px)': {
          width: '100%',
          '& .MuiDrawer-paper': {
            width: '100%'
          }
        }
        // '@media screen and (max-width: 1600px)': {
        //   width: '250px',
        //   '& .MuiDrawer-paper': {
        //     width: '250px'
        //   }
        // },
        // '@media screen and (max-width: 1200px)': {
        //   width: '200px',
        //   '& .MuiDrawer-paper': {
        //     width: '200px'
        //   }
        // }
      }}
      variant={drawerVariant ? 'permanent' : 'temporary'}
      anchor="left"
      open={drawerOpen}
    >
      <Grid className={styles.drawer_body} container direction={'column'}>
        <Grid className={styles.drawer_header}>
          <Grid className={styles.user_card}>
            {user?.avatar ? (
              <Grid className={styles.user_photo_container}>
                <img src={BACKEND_URL + user?.avatar?.toString()} />
                {/* <img src="/assets/images/user_photo.jpg" /> */}
              </Grid>
            ) : null}
            <Grid className={styles.user_label}>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleClick}
                className={styles.user_button}
                endIcon={
                  <KeyboardArrowDownIcon
                    style={{
                      transition: 'transform 0.2s',
                      transform: open ? 'rotate(180deg)' : 'none'
                    }}
                  />
                }
              >
                {user.firstname}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleProfileClick} disableRipple>
                  <PersonIcon />
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogOut} disableRipple>
                  <LogoutIcon />
                  Log out
                </MenuItem>
              </StyledMenu>
            </Grid>
          </Grid>
          <Grid className={styles.stats_wrapper}>
            <Card className={styles.stat_card}>
              <img
                src="/assets/images/user-icon.svg"
                style={{ marginTop: '2px' }}
              />
              <Grid className={styles.labels}>
                <Typography className={styles.label1}>
                  {(cardsData?.holding.ownership ?? '0') + '%'}
                </Typography>
                <Typography className={styles.label2}>Ownership</Typography>
              </Grid>
            </Card>
            <Card className={styles.stat_card}>
              <img src="/assets/images/podium.svg" />
              <Grid className={styles.labels}>
                <Typography className={styles.label1}>
                  {cardsData?.holding.rank}
                </Typography>
                <Typography className={styles.label2}>Rank</Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid className={styles.drawer_content}>
          <List className={styles.menu}>
            <StyledListItemButton
              selected={selectedMenuItem === 0}
              onClick={(event) => {
                handleMenuItemClick(0)
              }}
              className={styles.menu_item}
            >
              <ListItemIcon className={styles.menu_item_icon}>
                <Box className={`${styles.icon_box} icon_box`}>
                  <img src="/assets/images/Account.svg" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary="Account"
                className={styles.menu_item_text}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={selectedMenuItem === 1}
              onClick={(event) => {
                handleMenuItemClick(1)
              }}
              className={styles.menu_item}
            >
              <ListItemIcon className={styles.menu_item_icon}>
                <Box className={`${styles.icon_box} icon_box`}>
                  <img src="/assets/images/holdings.svg" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary="Holdings"
                className={styles.menu_item_text}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={selectedMenuItem === 2}
              onClick={(event) => {
                handleMenuItemClick(2)
              }}
              className={styles.menu_item}
            >
              <ListItemIcon className={styles.menu_item_icon}>
                <Box className={`${styles.icon_box} icon_box`}>
                  <img src="/assets/images/noteavailable.svg" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary="Available"
                className={styles.menu_item_text}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={selectedMenuItem === 3}
              onClick={(event) => {
                handleMenuItemClick(3)
              }}
              className={styles.menu_item}
            >
              <ListItemIcon className={styles.menu_item_icon}>
                <Box className={`${styles.icon_box} icon_box`}>
                  <img src="/assets/images/CurrencyCircleDollar.svg" />
                </Box>
              </ListItemIcon>
              <ListItemText primary="MDF" className={styles.menu_item_text} />
            </StyledListItemButton>
            <Divider sx={{ borderColor: 'rgba(29, 41, 112, 1)' }} />
            <StyledListItemButton
              selected={selectedMenuItem === 4}
              onClick={(event) => {
                handleMenuItemClick(4)
              }}
              className={styles.menu_item}
            >
              <ListItemIcon className={styles.menu_item_icon}>
                <Box className={`${styles.icon_box} icon_box`}>
                  <img src="/assets/images/Arrow.svg" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary="Simulation"
                className={styles.menu_item_text}
              />
            </StyledListItemButton>
            <Divider sx={{ borderColor: 'rgba(29, 41, 112, 1)' }} />
            <StyledListItemButton
              selected={selectedMenuItem === 5}
              onClick={(event) => {
                handleMenuItemClick(5)
              }}
              className={styles.menu_item}
            >
              <ListItemIcon className={styles.menu_item_icon}>
                <Box className={`${styles.icon_box} icon_box`}>
                  <img src="/assets/images/profit.svg" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary="Deposit"
                className={styles.menu_item_text}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={selectedMenuItem === 6}
              onClick={(event) => {
                handleMenuItemClick(6)
              }}
              className={styles.menu_item}
            >
              <ListItemIcon className={styles.menu_item_icon}>
                <Box className={`${styles.icon_box} icon_box`}>
                  <img src="/assets/images/Cash.svg" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary="Withdraw"
                className={styles.menu_item_text}
              />
            </StyledListItemButton>
          </List>
        </Grid>
        <Grid className={styles.drawer_footer}>
          <img src="/assets/images/logo.png" />
          <Grid className={styles.typography_wrapper}>
            <Typography className={styles.company_name}>
              Noble Vested Interests
            </Typography>
            <Typography className={styles.corporation}>CORPORATION</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default DashboardDrawer
