import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import styles from './styles.module.scss'
import { CustomTextField } from '../../components/custom.text.input'
import { CustomButton } from '../../components/custom.button'
import toast from 'react-hot-toast'
import { validateEmail } from '../../helpers'
import { loginUser, registerUser } from '../../helpers/api'
import { initializeApp } from '../../helpers/app'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../app.router/router.constants'

const SignUpPage: React.FC = () => {
  const navigate = useNavigate()
  const handleSubmit: () => void = () => {
    console.log('A: handleSubmit')
  }
  const [formValues, setFormValues] = useState({
    username: {
      value: '',
      error: false,
      errorMessage: 'You must enter a name'
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    },
    email: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    },
    confirm_password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    }
  })

  const handleIputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: 'username' | 'password' | 'email' | 'confirm_password'
  ): void => {
    const { value } = e.target
    // if (['password', 'name'].includes(name)) {
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
    // }
  }

  const validateField = (
    name: 'username' | 'password' | 'email' | 'confirm_password',
    value: string
  ): boolean => {
    let error = false
    let errorMessage = ''
    switch (name) {
      case 'username':
        if (value === '') {
          error = true
          errorMessage = 'You must enter a name'
        }
        break
      case 'password':
        if (value === '') {
          error = true
          errorMessage = 'You must enter a password'
        }
        break
      case 'email':
        if (value === '') {
          error = true
          errorMessage = 'You must enter an email'
        } else if (!validateEmail(value)) {
          error = true
          errorMessage = 'Email is incorrect'
        }
        break
      case 'confirm_password':
        if (value !== formValues.password.value) {
          error = true
          errorMessage = "Passwords doesn't match"
        }
        break

      default:
        break
    }
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        error,
        errorMessage
      }
    })

    return !error
  }
  const handleFocusOut = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: 'username' | 'password' | 'email' | 'confirm_password'
  ): void => {
    const { value } = e.target
    validateField(name, value)
  }

  // const handleSignUp = (): void => {
  //   if (
  //     validateField('username', formValues.username.value) &&
  //     validateField('email', formValues.email.value) &&
  //     validateField('password', formValues.password.value) &&
  //     validateField('confirm_password', formValues.confirm_password.value)
  //   ) {
  //     // api auth call now faked

  //     // const error = false
  //     // const errorMessage = 'Server error'
  //     // if (!error) {
  //     //   // useAppStore.setState({ user: dummyUser })
  //     // } else {
  //     //   toast.error(errorMessage)
  //     // }

  //     void registerUser(
  //       formValues.username.value,
  //       formValues.email.value,
  //       formValues.password.value
  //     )
  //       .then((data) => {
  //         void loginUser(formValues.email.value, formValues.password.value)
  //           .then((data) => {
  //             localStorage.setItem('userToken', data.data.access_token)
  //             void initializeApp().then(() => {
  //               navigate(ROUTES.Main)
  //             })
  //           })
  //           .catch((e) => {
  //             toast.error(e.message)
  //           })
  //       })
  //       .catch((e) => {
  //         toast.error(e.message)
  //       })
  //   }
  // }
  const handleSignUp = (): void => {
    if (
      validateField('username', formValues.username.value) &&
      validateField('email', formValues.email.value) &&
      validateField('password', formValues.password.value) &&
      validateField('confirm_password', formValues.confirm_password.value)
    ) {
      // Call the register API
      void registerUser(
        formValues.username.value,
        formValues.email.value,
        formValues.password.value
      )
        .then((response) => {
          // Show a success message
          toast.success(
            'You are successfully registered! Please contact the administrator for approval and wait for further information.'
          );
          void initializeApp().then(() => {
            navigate(ROUTES.Login);
          });
        })
        .catch((error) => {
          // Check if the error is a 422 (validation error)
          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.errors;
            Object.entries(validationErrors).forEach(([field, messages]) => {
              // Display each validation error as a toast message
              (messages as string[]).forEach((message) => {
                toast.error(message);
              });
            });
          } else {
            // Handle other types of errors
            toast.error(error.message || 'Registration failed. Please try again.');
          }
        });
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100dvh',
        width: '100vw',
        position: 'relative'
      }}
      className={styles.login_page}
    >
      <Grid className={styles.login_page_wrapper}>
        <Grid container className={styles.login_block}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            className={styles.login_form}
          >
            <Grid className={styles.logo_wrapper}>
              <img src="/assets/images/logo.png" />
              <Grid className={styles.typography_wrapper}>
                <Typography className={styles.company_name}>
                  Noble Vested Interests
                </Typography>
                <Typography className={styles.corporation}>
                  CORPORATION
                </Typography>
              </Grid>
            </Grid>
            <Grid className={styles.fields}>
              <CustomTextField
                autoComplete="given-name"
                name="username"
                required
                fullWidth
                id="firstName"
                label="Username"
                autoFocus
                // color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <AccountCircle /> */}
                      <img src="/assets/images/User.svg" />
                    </InputAdornment>
                  )
                }}
                variant="standard"
                error={formValues.username.error}
                helperText={formValues.username.errorMessage}
                onChange={(e) => {
                  handleIputChange(e, 'username')
                }}
                onBlur={(e) => {
                  handleFocusOut(e, 'username')
                }}
              />
              <CustomTextField
                name="email"
                required
                fullWidth
                id="email"
                label="Email"
                autoFocus
                // color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <AccountCircle /> */}
                      <img src="/assets/images/User.svg" />
                    </InputAdornment>
                  )
                }}
                variant="standard"
                error={formValues.username.error}
                helperText={formValues.username.errorMessage}
                onChange={(e) => {
                  handleIputChange(e, 'email')
                }}
                onBlur={(e) => {
                  handleFocusOut(e, 'email')
                }}
              />

              <CustomTextField
                name="password"
                label="Password"
                type="password"
                id="password"
                required
                fullWidth
                error={formValues.password.error}
                helperText={formValues.password.errorMessage}
                onChange={(e) => {
                  handleIputChange(e, 'password')
                }}
                onBlur={(e) => {
                  handleFocusOut(e, 'password')
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <AccountCircle /> */}
                      <img src="/assets/images/Key 5.svg" />
                    </InputAdornment>
                  )
                }}
                variant="standard"
              />
              <CustomTextField
                name="confirm_password"
                label="Confirm password"
                type="password"
                id="confirm_password"
                required
                fullWidth
                error={formValues.password.error}
                helperText={formValues.password.errorMessage}
                onChange={(e) => {
                  handleIputChange(e, 'confirm_password')
                }}
                onBlur={(e) => {
                  handleFocusOut(e, 'confirm_password')
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <AccountCircle /> */}
                      <img src="/assets/images/Key 5.svg" />
                    </InputAdornment>
                  )
                }}
                variant="standard"
              />
            </Grid>
            <Grid className={styles.buttons}>
              <CustomButton
                onClick={handleSignUp}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </CustomButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignUpPage
