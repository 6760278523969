import axios from 'axios'
import { BACKEND_API_URL } from '../../constants/api.constants'
import { useAppStore } from '../../storage'
import { getUserInfo } from '..'

axios.defaults.baseURL = BACKEND_API_URL

export const _setBearer = (): void => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    Accept: 'application/json'
  }
}

export const getDashboardCards = async (): Promise<any> => {
  const user = getUserInfo()
  if (user) {
    return axios.post('/dashboard/cards', {
      account_id: user.account_id
    })
  }
}

export const getUser = async (): Promise<any> => {
  // return axios.get('info', {})
  return axios.get('/account/user', {})
}

export const getTransactions = async (
  accountID: string | number
): Promise<any> => {
  return axios.get(`/account/transactions/${accountID}`)
}

export const getSubscriptions = async (
  accountID: string | number
): Promise<any> => {
  return axios.get(`/subscriptions/show/${accountID}`)
}

export const getHoldings = async (
  accountID?: number,
  securityID?: string
): Promise<any> => {
  return axios.post('/holding/search', {
    page: 1,
    limit: 999,
    where: [
      ['status', 'active'],
      ['account_id', accountID]
    ],
    orderby: { created_at: 'DESC' }
  })
}

export const getSecurities = async (securityID?: string): Promise<any> => {
  return axios.post('/security/search', {
    page: 1,
    limit: 999,
    fields: [
      'id',
      'security_number',
      'stock_price',
      'type',
      'put',
      'straddle',
      'total_price',
      'expected_value',
      'simulations',
      'status',
      'created_at'
    ],
    where: [
      ['status', 'active'], // Status must be active
      ['type', 'A'], // Type must be A
      ['simulations', '!=', 0] // Simulations must not be 0
    ],
    orderby: { created_at: 'DESC' } // Order by created_at in descending order
  })
}

export const getMDFTransactions = async (
  accountID: string | number
): Promise<any> => {
  return axios.get(`/account/transactions/${accountID}/?type=mdf`)
}

export const getMonthlyPayout = async (
  accountID: string | number
): Promise<any> => {
  return axios.get(`/investor/table/month-pay/${accountID}`)
}

export const getRanks = async (): Promise<any> => {
  return axios.get('/investor/table/ranks')
}

export const getPlans = async (): Promise<any> => {
  return axios.post('/plans/search')
}

export const sellHolding = async (
  holdingID: number,
  accountID: number
): Promise<any> => {
  return axios.post('/investor/holding/sell', {
    id: holdingID,
    account_id: accountID
  })
}

export const buySecurity = async (
  securityID: number,
  accountID: number
): Promise<any> => {
  return axios.post('/investor/security/buy', {
    id: securityID,
    account_id: accountID
  })
}

export const simulateSecurity = async (
  holdingID: number,
  securityID: number,
  securityNumber: number,
  simsNum?: number,
  simAll?: number
): Promise<any> => {
  try {
    // Make the API call using axios
    const response: any = await axios.post('/investor/security/simulate', {
      sim: simsNum ?? 1,
      simAll: simAll ?? 0,
      security: {
        id: securityID,
        security_number: securityNumber,
        holding_id: holdingID
      }
    })
    // Check if the response has a valid data structure
    if (!response.data) {
      throw new Error('Empty response from the server.')
    }
    // Return the parsed data
    return response.data
  } catch (e: any) {
    if (e.response) {
      // Server responded with a status code outside the 200-299 range
      console.error(
        `API Error: ${e.response.status} ${e.response.statusText}`,
        e.response.data
      )
      throw new Error(e.response.data?.message || 'Server returned an error.')
    } else if (e.request) {
      // No response was received from the server
      console.error('No response received from the server:', e.request)
      throw new Error('No response received from the server.')
    } else {
      // Other errors (e.g., network issues, invalid request)
      console.error('Error in simulateSecurity API:', e.message || e)
      throw new Error(e.message || 'An unexpected error occurred.')
    }
  }
}

export const getInvestorGraphInfo = async (
  holdingID: number,
  securityNumber: number
): Promise<any> => {
  return axios.post('/investor/graph/info', {
    security_number: securityNumber,
    holding_id: holdingID
  })
}

export const getInvestorTableInfo = async (
  securityNumber: number
): Promise<any> => {
  return axios.post('/investor/table/info', {
    // params: {
    //   security_number: securityNumber
    // }
    page: 1,
    limit: 100,
    where: [['security_number', securityNumber]]
  })
}

export const simulateHolding = async (
  simulationHolding: any,
  simsNum?: number,
  simAll?: number
): Promise<any> => {
  try {
    const simRespData: any = await simulateSecurity(
      simulationHolding.id,
      simulationHolding.security.id,
      simulationHolding.security.security_number,
      simAll ? simsNum : 1,
      simAll
    )

    // Check for the "operation in progress" status
    if (
      simRespData.status === 1 &&
      simRespData.message === 'Operation in progress'
    ) {
      throw new Error('Operation in progress') // Throwing an error to be caught by the toast
    }

    // Handle error status
    if (simRespData.status === 0) {
      const errorMessage =
        simRespData.errors?.stock_price?.[0] ||
        simRespData.message ||
        'Simulation failed due to server-side validation.'
      throw new Error(errorMessage)
    }

    // Check for valid data structure
    if (!simRespData?.data) {
      throw new Error('Invalid response structure from the server.')
    }

    // Update the global state with the new simulation data
    useAppStore.setState((state) => ({
      simulationHistoryData: simRespData.data.simulationHistory, // Update the history
      simulationGraphData: simRespData.data.simulationGraphInfo, // Update the graph history
      holdingsData: { ...state.holdingsData, data: simRespData.data.holdings }, // Update the holdings
      transactionsData: simRespData.data.transactions.data, // Update the transactions
      mdfData: {
        ...state.mdfData,
        transactions: simRespData.data.transactions,
        graphTransactions: simRespData.data.graphTransactions
      }, // Update the account mdf data including transactions graph
      // Decrement the available simulations
      simulationHolding: {
        ...state.simulationHolding,
        security: simRespData.data.security,
        sim_A: simRespData.data.sim_A,
        sim_B: simRespData.data.sim_B,
        simulations: state.simulationHolding.simulations - 1
      }
    }))

    return simRespData.data
  } catch (e: any) {
    console.error('Simulation API Error:', e.message || e)
    throw e
  }
}

export const getInvestorInfo = async (simulationHolding: any): Promise<any> => {
  await getInvestorTableInfo(simulationHolding.security.security_number)
    .then((respData) => {
      useAppStore.setState({
        simulationHistoryData: respData.data
      })
    })
    .catch((e) => {
      console.error(e)
    })

  await getInvestorGraphInfo(
    simulationHolding.id,
    simulationHolding.security.security_number
  )
    .then((respData) => {
      useAppStore.setState({
        simulationGraphData: respData.data.data
      })
    })
    .catch((e) => {
      console.error(e)
    })
}

export const createStripeAccount = async (
  firstname: string,
  lastname: string,
  email: string
): Promise<any> => {
  const data = {
    owner_type: 'user',
    name: firstname + ' ' + lastname,
    email,
    type: 'individual'
  }
  return axios.post('/my/payment/profile', data)
}

export const selectPlan = async (id: string | number): Promise<any> => {
  return axios.get(`/plans/select/${id}`)
}

export const getProfiles = async (): Promise<any> => {
  return axios.get('/profiles')
}

export const getData = async (token?: string): Promise<any> => {
  const dashboardInfo = (await getDashboardCards()).data?.data

  if (dashboardInfo) {
    const cardsData = dashboardInfo?.data
    const accountID = dashboardInfo?.account_id

    useAppStore.setState({ cardsData, accountID })

    getTransactions(accountID)
      .then((data) => {
        useAppStore.setState({
          transactionsData: data.data.data.transactions.data
        })
        useAppStore.setState({ mdfData: data.data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    getUser()
      .then((data) => {
        useAppStore.setState({ selectedPlan: data.data.data.plan_id })
      })
      .catch((e) => {
        console.error(e)
      })
    getHoldings(accountID)
      .then((data) => {
        useAppStore.setState({ holdingsData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    getSecurities()
      .then((data) => {
        useAppStore.setState({ securitiesData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    getMonthlyPayout(accountID)
      .then((data) => {
        useAppStore.setState({ monthlyPayoutData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    getRanks()
      .then((data) => {
        useAppStore.setState({ rankingsData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })

    getPlans()
      .then((data) => {
        useAppStore.setState({ plansData: data.data.data })
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // return later(100, dummyData)
  // return await later(100, dummyData)
}

export const loginUser = async (
  name: string,
  password: string
): Promise<any> => {
  return await axios.post(
    // 'https://nvi.swellmadeit.com/api/login',
    '/login',
    {
      email: name,
      password: password
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': ' ',
        Accept: 'application/json'
      }
    }
  )

  // return await axios.get(BACKEND_API_URL + '/holding/search', {
  //   headers: {
  //     Authorization: 'Bearer 7|z8AVZwl32Gvl4u4SplXfj3F9HQEIhdDpeRnQLINi'
  //     // 'Content-Type': 'application/json',
  //     // 'X-CSRF-TOKEN': '',
  //     // accept: 'application/json'
  //   }
  // })
  // if (name === 'bjack@gmail.com' && password === 'bjack123') {
  //   return await later(100, dummyUserToken)
  // } else {
  //   throw Error('Wrong credentials')
  // }
}

export const updateUser = async (data: {
  firstname?: string
  lastname?: string
  email?: string
  country?: string
  state?: string
  password?: string
  avatarFile?: any
}): Promise<any> => {
  const formData = new FormData()
  data.firstname && formData.append('firstname', data.firstname)
  data.lastname && formData.append('lastname', data.lastname)
  data.email && formData.append('email', data.email)
  data.country && formData.append('country', data.country)
  data.state && formData.append('state', data.state)
  data.avatarFile && formData.append('avatarFile', data.avatarFile)
  if (data.password) {
    formData.append('password', data.password)
    formData.append('password_confirmation', data.password)
  }
  return await axios.post('/investor/profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-CSRF-TOKEN': ' ',
      Accept: 'application/json'
    }
  })

  // return await axios.get(BACKEND_API_URL + '/holding/search', {
  //   headers: {
  //     Authorization: 'Bearer 7|z8AVZwl32Gvl4u4SplXfj3F9HQEIhdDpeRnQLINi'
  //     // 'Content-Type': 'application/json',
  //     // 'X-CSRF-TOKEN': '',
  //     // accept: 'application/json'
  //   }
  // })
  // if (name === 'bjack@gmail.com' && password === 'bjack123') {
  //   return await later(100, dummyUserToken)
  // } else {
  //   throw Error('Wrong credentials')
  // }
}

export const registerUser = async (
  name: string,
  email: string,
  password: string
): Promise<any> => {
  return await axios.post(
    '/register',
    {
      firstname: name.split(' ')[0],
      lastname: name.split(' ')[1],
      email,
      password,
      password_confirmation: password
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': ' ',
        Accept: 'application/json'
      }
    }
  )

  // return await axios.get(BACKEND_API_URL + '/holding/search', {
  //   headers: {
  //     Authorization: 'Bearer 7|z8AVZwl32Gvl4u4SplXfj3F9HQEIhdDpeRnQLINi'
  //     // 'Content-Type': 'application/json',
  //     // 'X-CSRF-TOKEN': '',
  //     // accept: 'application/json'
  //   }
  // })
  // if (name === 'bjack@gmail.com' && password === 'bjack123') {
  //   return await later(100, dummyUserToken)
  // } else {
  //   throw Error('Wrong credentials')
  // }
}
