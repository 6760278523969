import * as React from 'react'
import styles from './styles.module.scss'
import { Grid, type SelectChangeEvent, Typography } from '@mui/material'
import { type ITransaction, type IUserData } from '../../types'
import CustomChart, { type IGraphItemData } from '../../components/chart'
import CustomTable from '../../components/custom.table'
import CustomSelect from '../../components/custom.select'
import { useAppStore } from '../../storage'
import moment from 'moment'
import { getTransactionSignChar } from '../../helpers'
const MONTHLY_TABLE_HEADERS = ['Date', 'Payout']
const RANKINGS_TABLE_HEADERS = ['Rank', 'Payout', 'Sims']

interface IAccountTabProps {
  user: IUserData
}

const MDFTab: React.FC<IAccountTabProps> = ({ user }) => {
  const [isScreenSmall, setIsScreenSmall] = React.useState(false)
  const { mdfData, rankingsData, monthlyPayoutData } = useAppStore()
  const [graphData, setGraphData] = React.useState<any>(null)
  const [viewVariant, setViewVariant] = React.useState<number>(0)
  const [markedRankingsArr, setMarkedRankingsArr] = React.useState<number[]>([])
  const [tableCells1, setTableCells1] = React.useState<
    (JSX.Element | string | number)[][] | null
  >(null)
  const [tableCells2, setTableCells2] = React.useState<
    (JSX.Element | string | number)[][] | null
  >(null)

  const handleVariantInputChange = (e: SelectChangeEvent<unknown>): void => {
    setViewVariant(e.target.value as number)
  }

  const handleMDFData = async (): Promise<void> => {
    if (mdfData) {
      const data = mdfData
      const dataDraw: IGraphItemData[] = []
      const _transactions: ITransaction[] = []

      for (let i = data.graphTransactions.length - 1; i >= 0; i--) {
        dataDraw.push({
          label: moment(data.graphTransactions[i].created_at).format('MMM'),
          value: parseFloat(data.graphTransactions[i].ending_balance) / 10000,
          date: moment(data.graphTransactions[i].created_at).format(
            'DD MMM, h:mm:ssA'
          ),
          profit:
            getTransactionSignChar(data.graphTransactions[i]) +
            (
              (data.graphTransactions[i].amount * 100) /
              data.graphTransactions[i].ending_balance
            ).toFixed(2) +
            '%'
        })

        _transactions.push({
          type: data.transactions.data[i].operation,
          time: moment(data.transactions.data[i].created_at).format('hh:mm'),
          value: data.graphTransactions[i].ending_balance,
          difference:
            getTransactionSignChar(data.transactions.data[i]) +
            '$' +
            data.transactions.data[i].amount.toFixed(2),
          id: data.transactions.data[i].transaction_id,
          name: data.transactions.data[i].operation
        })
      }

      setGraphData(dataDraw)
    }
  }
  const handleRankingsData = async (): Promise<void> => {
    if (rankingsData) {
      const data = rankingsData.data; // Rankings data from AppStore
      const tableRankings: any[] = [];
      const markedIndex: number[] = [];

      data.forEach((r: any, ind: number) => {
        const row: any[] = [];
        row.push(r.rank); // Rank
        row.push('$' + parseFloat(r.payout).toFixed(2)); // Payout
        row.push(r.sims); // Sims
        tableRankings.push(row);
        markedIndex.push(ind); // Store indices for styling (if needed)
      });

      setTableCells2(tableRankings); // Update rankings table data
      setMarkedRankingsArr(markedIndex); // Mark rows for styling
    }
  };
  const handleMonthlyPayoutData = async (): Promise<void> => {
    if (monthlyPayoutData) {
      const data = monthlyPayoutData.data
      const tablePayouts: any[] = []

      data.forEach((r: any, ind: number) => {
        const row: any[] = []
        row.push(moment(r.date).format('MMM YYYY'))
        row.push(new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(r.amount))
        tablePayouts.push(row)
      })
      setTableCells1(tablePayouts)
    }
  }

  React.useEffect(() => {
    void handleRankingsData()
  }, [rankingsData])

  React.useEffect(() => {
    void handleMDFData()
  }, [mdfData])

  React.useEffect(() => {
    void handleMonthlyPayoutData()
  }, [monthlyPayoutData])

  React.useEffect(() => {
    const onResize = (): void => {
      if (window.innerWidth <= 1024) {
        setIsScreenSmall(true)
      } else {
        setIsScreenSmall(false)
      }
    }
    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <Grid
      display={'grid'}
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      className={styles.account_tab_wrapper}
    >
      <Grid gridColumn="span 9" className={styles.securities}>
        <div className={styles.securities_wrapper}>
          <Grid className={styles.header}>
            <Typography className={styles.title}>Monthly Payouts</Typography>
            <div className={styles.custom_select_768}>
              <CustomSelect
                value={viewVariant}
                defaulValue={'Chart'}
                list={['Chart', 'Table']}
                onChangeCallback={handleVariantInputChange}
              />
            </div>
          </Grid>
          <Grid
            className={styles.body}
            display={'grid'}
            gridTemplateColumns="repeat(12, 1fr)"
            gap={2}
          >
            <Grid className={styles.table1} gridColumn="span 4">
              {!isScreenSmall && tableCells1 ? (
                <CustomTable
                  headers={MONTHLY_TABLE_HEADERS}
                  cells={tableCells1}
                  type="payouts"
                />
              ) : null}
            </Grid>
            <Grid
              className={`${styles.chart} ${viewVariant === 0 ? styles.active : ''
                }`}
              gridColumn="span 8"
            >
              <CustomChart data={graphData} />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        gridColumn="span 3"
        className={`${styles.rankings_wrapper} ${viewVariant === 1 ? styles.active : ''
          }`}
      >
        <Grid className={styles.header}>
          <Typography className={styles.title}>Rankings</Typography>
        </Grid>
        <Grid className={styles.table1_1024}>
          {isScreenSmall && tableCells1 ? (
            <CustomTable headers={MONTHLY_TABLE_HEADERS} cells={tableCells1} />
          ) : null}
        </Grid>
        <Grid className={styles.table2}>
          {tableCells2 ? (
            <CustomTable
              headers={RANKINGS_TABLE_HEADERS}
              cells={tableCells2}
              markedArr={markedRankingsArr}
              type="rankings"
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MDFTab
