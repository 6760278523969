import * as React from 'react'
import { LineChart } from '@mui/x-charts/LineChart'
import { Box, Paper, Typography } from '@mui/material'
// import moment from 'moment'
import styles from './styles.module.scss'

export interface IGraphTooltipData {
  id?: number
  value?: number
  date: string
  profit: number
}

export const years = [
  new Date(1990, 0, 1),
  new Date(1991, 0, 1),
  new Date(1992, 0, 1),
  new Date(1993, 0, 1),
  new Date(1994, 0, 1),
  new Date(1995, 0, 1),
  new Date(1996, 0, 1),
  new Date(1997, 0, 1),
  new Date(1998, 0, 1),
  new Date(1999, 0, 1),
  new Date(2000, 0, 1),
  new Date(2001, 0, 1),
  new Date(2002, 0, 1),
  new Date(2003, 0, 1),
  new Date(2004, 0, 1),
  new Date(2005, 0, 1),
  new Date(2006, 0, 1),
  new Date(2007, 0, 1),
  new Date(2008, 0, 1),
  new Date(2009, 0, 1),
  new Date(2010, 0, 1),
  new Date(2011, 0, 1),
  new Date(2012, 0, 1),
  new Date(2013, 0, 1),
  new Date(2014, 0, 1),
  new Date(2015, 0, 1),
  new Date(2016, 0, 1),
  new Date(2017, 0, 1),
  new Date(2018, 0, 1)
]

// const FranceGDPperCapita = [
//   28129, 28294.264, 28619.805, 28336.16, 28907.977, 29418.863, 29736.645,
//   30341.807, 31323.078, 32284.611, 33409.68, 33920.098, 34152.773, 34292.03,
//   35093.824, 35495.465, 36166.16, 36845.684, 36761.793, 35534.926, 36086.727,
//   36691, 36571, 36632, 36527, 36827, 37124, 37895, 38515.918
// ]

// const UKGDPperCapita = [
//   26189, 25792.014, 25790.186, 26349.342, 27277.543, 27861.215, 28472.248,
//   29259.764, 30077.385, 30932.537, 31946.037, 32660.441, 33271.3, 34232.426,
//   34865.78, 35623.625, 36214.07, 36816.676, 36264.79, 34402.36, 34754.473,
//   34971, 35185, 35618, 36436, 36941, 37334, 37782.83, 38058.086
// ]

export const GermanyGDPperCapita = [
  25391, 26769.96, 27385.055, 27250.701, 28140.057, 28868.945, 29349.982,
  30186.945, 31129.584, 32087.604, 33367.285, 34260.29, 34590.93, 34716.44,
  35528.715, 36205.574, 38014.137, 39752.207, 40715.434, 38962.938, 41109.582,
  43189, 43320, 43413, 43922, 44293, 44689, 45619.785, 46177.617
]

// interface ICustomChartProps {
//   test?: string
//   data?: any
// }

// export interface IGraphData {
//   labels: string[]
//   dataDraw: number[]
//   numberToCompare?: number
//   graphTooltipData?: IGraphTooltipData[]
// }
// export interface IGraphItemData {
//   label: string
//   value: string | number
//   date: string
//   profit: string
// }

export interface ISimulationGraphItemData {
  data?: {
    labels: string[]
    dataInvestor: number[]
    dataNvic: number[]
  }
  // profit?: string
}

export const CustomItemTooltipContent = (props: any): JSX.Element => {
  const { series } = props
  const data = props.series[0].dataset[props.dataIndex]
  let color = 'rgba(40, 161, 133, 1)'
  if (data?.profit && data.profit[0] === '-') {
    color = 'rgba(180, 52, 103, 1)'
  }

  return (
    <Paper
      sx={{ padding: 3, backgroundColor: series.color }}
      className={styles.chart_tooltip}
    >
      <Box component={'span'} className={styles.value_wrapper}>
        <Typography display="inline" className={styles.value}>
          {'$' + Math.round(props.axisData.y.value / 10000)}
        </Typography>
        <Typography
          display="inline"
          color={color}
          className={styles.difference}
        >
          {data?.profit}
        </Typography>
      </Box>
      <Typography className={styles.date}>{data?.date}</Typography>
      {/* <p>{series.label}</p>
      <p>{series.data[itemData.dataIndex]}</p> */}
    </Paper>
  )
}

const SimulationChart: React.FC<ISimulationGraphItemData> = ({ data }) => {
  if (!data?.labels?.length || !data.dataInvestor?.length) {
    return (
      <div className={styles.chartPlaceholder}>
        <Typography variant="h6">
          No simulation data available
        </Typography>
      </div>
    );
  }

  return (
    <>
      {data ? (
        <LineChart
          // dataset={data}
          slotProps={{
            legend: {
              labelStyle: {
                // fontSize: 14,
                fill: 'white'
              }
            }
          }}
          yAxis={[
            {
              id: 'Germany',
              scaleType: 'linear',
              valueFormatter: (axisData) => {
                return '$' + axisData
              }
            }
          ]}
          sx={{
            '& .MuiLineElement-root': {
              // strokeDasharray: '10 5',
              strokeWidth: 4,
              // stroke: '#386CBF',
              '&.MuiAreaElement-series-Germany': {
                fill: "url('#myGradient')"
              }
            },
            '& .MuiAreaElement-series-Germany': {
              fill: "url('#myGradient')"
            },
            '& .MuiChartsAxis-line': {
              display: 'none'
            },
            '& .MuiChartsLegend-series': {
              '&text': {
                fill: 'white'
              },
              color: 'white'
            },
            '& .MuiChartsAxis-tickLabel': {
              fill: '#7980A9 !important',
              fontFamily: 'Lato !important',
              // fontSize: '0.8em !important',
              fontStyle: 'normal !important',
              fontWeight: '500 !important',
              lineHeight: 'normal'
            },
            '& .MuiChartsAxisHighlight-root': {
              stroke: 'white',
              // stroke: 'url(#paint0_linear_128_1177)',
              fill: 'red',
              strokeDasharray: 'none'
            }
          }}
          xAxis={[{
            data: data.labels,
            scaleType: 'point',
            label: 'Simulation Index'
          }]}
          series={[
            {
              data: data.dataInvestor,
              label: 'Investor',
              showMark: false
            },
            {
              data: data.dataNvic,
              label: 'NVIC',
              showMark: false
            }
          ]}
          // xAxis={[
          //   {
          //     id: 'Years',
          //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment

          //     data: data?.labels,
          //     // data: [1, 2, 3, 5, 8, 10, 8, 9, 9]
          //     // data: [1, 2, 3, 4, 5]
          //     scaleType: 'point'
          //     // valueFormatter: (date) => date // .getFullYear().toString()
          //   }
          // ]}
          // series={[
          //   {
          //     id: 'Germany',
          //     data: data?.dataDraw,
          //     // data: [1, 2, 3, 4, 5],
          //     stack: 'total',
          //     area: true,
          //     showMark: false,
          //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //     // @ts-expect-error
          //     numberToCompare: 200
          //   }
          // ]}
          margin={{ left: 60, top: 10, right: 20, bottom: 25 }}
        // height={400}
        // tooltip={{ trigger: 'axis',
        //   axisContent: CustomItemTooltipContent
        // }}
        >
          <defs>
            <linearGradient id="myGradient" gradientTransform="rotate(90)">
              <stop stopColor="#134798" />
              <stop offset="1" stopColor="#090C2B" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient id="paint0_linear_128_1177">
              <stop stopColor="red" />
              <stop offset="1" stopColor="yellow" />
            </linearGradient>
          </defs>
        </LineChart>
      ) : null}
    </>
  )
}

export default SimulationChart
