import * as React from 'react'
import { type IUserData } from '../../../types'
import styles from './styles.module.scss'
import { Grid } from '@mui/material'
import { useAppStore } from '../../../storage'
import AccountTab from '../../../pages/account'
import HoldingsTab from '../../../pages/holdings'
import AvailableTab from '../../../pages/available'
import MDFTab from '../../../pages/mdf'
import SimulationTab from '../../../pages/simulation/index'

interface IDashboardContentProps {
  user: IUserData
}

const DashboardContent: React.FC<IDashboardContentProps> = ({ user }) => {
  const { activeDashboardTab } = useAppStore()

  const renderTab = (activeTab: number | null): JSX.Element | undefined => {
    switch (activeTab) {
      case 0:
        return <AccountTab user={user} />
      case 1:
        return <HoldingsTab user={user} />
      case 2:
        return <AvailableTab user={user} />
      case 3:
        return <MDFTab user={user} />
      case 4:
        return <SimulationTab user={user} />
      default:
        break
    }
  }

  return (
    <Grid className={styles.dashboard_content}>
      {renderTab(activeDashboardTab)}
    </Grid>
  )
}

export default DashboardContent
