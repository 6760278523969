import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import styles from './styles.module.scss'
import { CustomTextField } from '../../components/custom.text.input'
import { CustomButton } from '../../components/custom.button'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../app.router/router.constants'
import { loginUser } from '../../helpers/api'
import { initializeApp } from '../../helpers/app'

const LoginPage: React.FC = () => {
  const navigate = useNavigate()
  const handleSubmit: () => void = () => {
    console.log('A: handleSubmit')
  }
  const [formValues, setFormValues] = useState({
    username: {
      value: '',
      error: false,
      errorMessage: 'You must enter a name'
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    }
  })

  const handleIputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: 'username' | 'password'
  ): void => {
    const { value } = e.target
    // if (['password', 'name'].includes(name)) {
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
    // }
  }
  const validateField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: 'username' | 'password'
  ): void => {
    const { value } = e.target
    let error = false
    if (value === '') {
      error = true
    }
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        error
      }
    })
  }
  const handleSignIn = (): void => {
    if (formValues.username.value && formValues.password.value) {
      void loginUser(formValues.username.value, formValues.password.value)
        .then((data) => {
          if (data.data.status) {
            localStorage.setItem('userToken', data.data.data.token)
            localStorage.setItem('userInfo', JSON.stringify(data.data.data))
            void initializeApp().then(() => {
              navigate(ROUTES.Main)
            })
          } else {
            toast.error(data.data.message)
          }
        })
        .catch((e) => {
          toast.error(e.message)
        })
    }
  }

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100dvh',
        width: '100vw',
        position: 'relative'
      }}
      className={styles.login_page}
    >
      <Grid className={styles.login_page_wrapper}>
        <Grid container className={styles.login_block}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            className={styles.login_form}
          >
            <Grid className={styles.logo_wrapper}>
              <img src="/assets/images/logo.png" />
              <Grid className={styles.typography_wrapper}>
                <Typography className={styles.company_name}>
                  Noble Vested Interests
                </Typography>
                <Typography className={styles.corporation}>
                  CORPORATION
                </Typography>
              </Grid>
            </Grid>
            <Grid className={styles.fields}>
              <CustomTextField
                autoComplete="given-name"
                name="username"
                required
                fullWidth
                id="firstName"
                label="Username"
                autoFocus
                // color="primary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <AccountCircle /> */}
                      <img src="/assets/images/User.svg" />
                    </InputAdornment>
                  )
                }}
                variant="standard"
                error={formValues.username.error}
                helperText={formValues.username.errorMessage}
                onChange={(e) => {
                  handleIputChange(e, 'username')
                }}
                onBlur={(e) => {
                  validateField(e, 'username')
                }}
              />

              <CustomTextField
                name="password"
                label="Password"
                type="password"
                id="password"
                required
                fullWidth
                error={formValues.password.error}
                helperText={formValues.password.errorMessage}
                onChange={(e) => {
                  handleIputChange(e, 'password')
                }}
                onBlur={(e) => {
                  validateField(e, 'password')
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <AccountCircle /> */}
                      <img src="/assets/images/Key 5.svg" />
                    </InputAdornment>
                  )
                }}
                variant="standard"
              />
            </Grid>
            <Grid className={styles.buttons}>
              <CustomButton
                onClick={handleSignIn}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </CustomButton>
              <CustomButton
                // type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  navigate(ROUTES.SignUp)
                }}
              >
                Sign Up
              </CustomButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoginPage
