import * as React from 'react'
import { type IUserData } from '../../types'
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  type MenuProps,
  Typography,
  styled
} from '@mui/material'
import styles from './styles.module.scss'
import TableSearch from '../../components/table.search'
import CustomTable from '../../components/custom.table'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useAppStore } from '../../storage'
import { getHoldings, sellHolding, getInvestorInfo, getData } from '../../helpers/api'
import toast from 'react-hot-toast'

interface IHoldingsTabProps {
  user: IUserData
}

const TABLE_HEADERS = [
  'Security',
  'Sims',
  'Market Price',
  'Days to Maturity',
  'Expected Value',
  ''
]

const TABLE_HEADERS_MOBILE = ['Security', 'Sims', 'Market Price', '']

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({}))

export const holdingsRequest = (accountID?: number): void => {
  getHoldings(accountID)
    .then((data) => {
      useAppStore.setState({ holdingsData: data.data })
    })
    .catch((e) => {
      console.error(e)
    })
}

export const HoldingsActionButton: React.FC<{
  holding: any
  accountID: any
}> = ({ holding, accountID }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const security = holding.security_id
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleSimulateClick = async (
    holding: {
      id: number
      security: number
      security_number: number
      simulations: number
    }
  ): Promise<void> => {
    try {
      await getInvestorInfo(holding);
      useAppStore.setState({ simulationHolding: holding })
      useAppStore.setState({ activeDashboardTab: 4 });
    } catch (error) {
      console.error('Error fetching investor data:', error);
    }
  }

  const handleSell = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>): Promise<any> => {
    try {
      await toast
        .promise(sellHolding(holding.id, accountID), {
          loading: 'Process...',
          success: (data) => {
            if (data?.data.errors?.length) throw new Error(data?.data.errors[0])
            else holdingsRequest(accountID)
            void getData();
            return 'Success!'
          },
          error: (err) => err.message
        })
        .catch((e) => {
          console.log(e)
        })
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Button
        id={'action_button' + security.toString()}
        aria-controls={open ? 'action_menu' + security.toString() : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        className={`${styles.user_button} ${open ? styles.active : ''}`}
        startIcon={
          <img
            className={styles.user_button_icon}
            src="/assets/images/Arrow.svg"
          />
        }
        endIcon={
          <KeyboardArrowDownIcon
            className={styles.user_button_icon}
            style={{
              color: 'rgba(39, 110, 192, 1)',
              transition: 'transform 0.2s',
              transform: open ? 'rotate(180deg)' : 'none'
            }}
          />
        }
        sx={{
          '& .MuiButton-startIcon': {
            marginRight: '1em',
            marginLeft: '0px'
          },
          '& .MuiButton-endIcon': {
            marginLeft: '0.6em',
            marginRight: '0px'
          }
        }}
      >
        Actions
      </Button>
      <StyledMenu
        id={'action_menu' + security.toString()}
        MenuListProps={{
          'aria-labelledby': 'action_button' + security.toString()
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {holding.security.simulations > 0 ? (
          <>
            <MenuItem
              onClick={() => {
                void handleSimulateClick({
                  id: holding.id,
                  security: holding.security,
                  security_number: holding.security.security_number,
                  simulations: holding.security.simulations
                })
              }}
              disableRipple
            >
              Simulate
            </MenuItem>
          </>
        ) : null}
        <MenuItem onClick={(event) => {
          void handleSell(event); // Explicitly ignore the returned promise here
        }} disableRipple>
          Sell
        </MenuItem>
      </StyledMenu>
    </>
  )
}

const HoldingsTab: React.FC<IHoldingsTabProps> = ({ user }) => {
  const { holdingsData, accountID } = useAppStore()
  const tableVariant = useMediaQuery('(min-width:600px)')
  const handleSearch = (value: string): void => {
    console.log('A: handleSearch', value)
  }

  const [tableCells, setTableCells] = React.useState<
    (JSX.Element | string | number)[][] | null
  >(null)

  const getHoldingsData = async (): Promise<void> => {
    const data = holdingsData.data;
    const tableHoldings: any[] = []

    data.forEach((holding: any) => {
      let row: any[] = []
      row.push(holding.security.security_number)
      row.push(holding.security.simulations)
      row.push('$' + holding.security.stock_price / 10000)
      row.push(holding.security.maturity_date)
      row.push('$' + holding.security.expected_value / 10000)
      if (!tableVariant) {
        row = row.splice(row.length - 3)
      }
      row.push(
        <HoldingsActionButton
          holding={holding}
          accountID={accountID}
        />
      )
      tableHoldings.push(row)
    })
    setTableCells(tableHoldings)
  }

  React.useEffect(() => {
    if (holdingsData) {
      getHoldingsData().then(() => { }).catch((err) => {
        console.error(err)
      })
    }
    else {
      holdingsRequest(accountID)
    }
  }, [holdingsData])

  return (
    <Grid className={styles.holdings_tab} container direction={'column'}>
      <div className={styles.holdings_wrapper}>
        <Grid className={styles.header}>
          <Typography className={styles.name}>
            Your Security Holdings
          </Typography>
          <TableSearch
            onSearchCallback={handleSearch}
            helperText={'Search for...'}
          />
        </Grid>
        {tableCells ? (
          <CustomTable
            headers={tableVariant ? TABLE_HEADERS : TABLE_HEADERS_MOBILE}
            cells={tableCells}
          />
        ) : null}
      </div>
    </Grid>
  )
}

export default HoldingsTab
