import { Card, Grid, Typography } from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'
import { type IUserData } from '../../../../types'
import { useAppStore } from '../../../../storage'

interface IDashboardPanelProps {
  user: IUserData
}
// const nfObject = new Intl.NumberFormat('en-US')
const nfObject2 = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })

const DashboardPanel: React.FC<IDashboardPanelProps> = ({ user }) => {
  const [activeTab, setActiveTab] = React.useState<null | number>(null)
  const { activeDashboardTab, cardsData } = useAppStore()
  const handleTabClick = (tabIndex: number): void => {
    setActiveTab(tabIndex)

    useAppStore.setState({ activeDashboardTab: tabIndex })
  }
  React.useEffect(() => {
    if (activeDashboardTab !== activeTab) {
      setActiveTab(activeDashboardTab)
    }
  }, [activeDashboardTab])
  return (
    <Grid
      className={`${styles.panel} ${activeTab && activeTab > 3 ? styles.hidden : ''
        }`}
    >
      <Grid
        className={`${styles.card_wrapper} ${activeTab === 0 ? styles.active : ''
          }`}
      >
        <Card
          className={`${styles.card} ${activeTab === 0 ? styles.active : ''}`}
          onClick={() => {
            handleTabClick(0)
          }}
        >
          <Typography className={styles.card_name}>Account</Typography>
          <Grid className={styles.card_value}>
            <Typography className={styles.label}>Total value</Typography>
            <Typography className={styles.value}>
              {'$' +
                (
                  ((cardsData?.account?.balance) ? (cardsData?.account?.balance) / 100 : 0) +
                  (cardsData?.account?.unrealized ?? 0)
                ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid className={styles.card_stats}>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>Cash</Typography>
              <Typography className={styles.stat_value}>
                {'$' + ((cardsData?.account?.balance) ? (cardsData?.account?.balance) / 100 : 0).toFixed(2)}
              </Typography>
            </Card>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>Securites</Typography>
              <Typography className={styles.stat_value}>
                {'$' + (cardsData?.account.unrealized ?? 0).toFixed(2)}
              </Typography>
            </Card>
          </Grid>
        </Card>
      </Grid>
      <Grid
        className={`${styles.card_wrapper} ${activeTab === 1 ? styles.active : ''
          }`}
      >
        <Card
          className={`${styles.card} ${activeTab === 1 ? styles.active : ''}`}
          onClick={() => {
            handleTabClick(1)
          }}
        >
          <Typography className={styles.card_name}>Holdings</Typography>
          <Grid className={styles.card_value}>
            <Typography className={styles.label}>Securities Owned</Typography>
            <Typography className={styles.value}>
              {cardsData?.holding.sec_owned}
            </Typography>
          </Grid>
          <Grid className={styles.card_stats}>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>Sims Owned</Typography>
              <Typography className={styles.stat_value}>
                {cardsData?.holding.sims_owned}
              </Typography>
            </Card>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>
                Ownership %
              </Typography>
              <Typography className={styles.stat_value}>
                {cardsData?.holding.ownership}
              </Typography>
            </Card>
          </Grid>
        </Card>
      </Grid>
      <Grid
        className={`${styles.card_wrapper} ${activeTab === 2 ? styles.active : ''
          }`}
      >
        <Card
          className={`${styles.card} ${activeTab === 2 ? styles.active : ''}`}
          onClick={() => {
            handleTabClick(2)
          }}
        >
          <Typography className={styles.card_name}>Available</Typography>
          <Grid className={styles.card_value}>
            <Typography className={styles.label}>
              Securities Available
            </Typography>
            <Typography className={styles.value}>
              {cardsData?.securities.number_sec}
            </Typography>
          </Grid>
          <Grid className={styles.card_stats}>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>
                Sims Available
              </Typography>
              <Typography className={styles.stat_value}>
                {/* {'$' +
                  nfObject.format(parseFloat(cardsData?.securities.sims ?? ''))} */}
                {cardsData?.securities.sims}
              </Typography>
            </Card>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>
                Total Available
              </Typography>
              <Typography className={styles.stat_value}>
                {'$' +
                  nfObject2.format(cardsData?.securities.dollar_amount ?? 0)}
              </Typography>
            </Card>
          </Grid>
        </Card>
      </Grid>
      <Grid
        className={`${styles.card_wrapper} ${activeTab === 3 ? styles.active : ''
          }`}
      >
        <Card
          className={`${styles.card} ${activeTab === 3 ? styles.active : ''}`}
          onClick={() => {
            handleTabClick(3)
          }}
        >
          <Typography className={styles.card_name}>MDF</Typography>
          <Grid className={styles.card_value}>
            <Typography className={styles.label}>Total value</Typography>
            <Typography className={styles.value}>
              {'$' + nfObject2.format(cardsData?.mdf.balance ?? 0)}
            </Typography>
          </Grid>
          <Grid className={styles.card_stats}>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>Total MDF</Typography>
              <Typography className={styles.stat_value}>
                {'$' + nfObject2.format(cardsData?.mdf.eom ?? 0)}
              </Typography>
            </Card>
            <Card className={styles.stat}>
              <Typography className={styles.stat_label}>
                Previous E.O.M.
              </Typography>
              <Typography className={styles.stat_value}>
                {'$' + nfObject2.format(cardsData?.mdf.previous_month ?? 0)}
              </Typography>
            </Card>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DashboardPanel
