import { create } from 'zustand'
import { type IUserData, type IUser, type ICardsData } from '../types'

interface IAppStore {
  user?: IUser
  lastPage: string
  userToken?: string
  activeDashboardTab: null | number
  drawerOpen: boolean
  selectedSecurity: string

  userData?: IUserData
  accountID?: undefined
  cardsData?: ICardsData
  transactionsData?: any
  holdingsData?: any
  securitiesData?: any
  mdfData?: any
  monthlyPayoutData?: any
  rankingsData?: any
  simulationHolding: any
  simulationHistoryData: any
  simulationGraphData: any
  plansData: any
  selectedPlan: number
}

export const useAppStore = create<IAppStore>(() => ({
  user: undefined,
  userData: undefined,
  transactionsData: undefined,
  holdingsData: undefined,
  securitiesData: undefined,
  mdfData: undefined,
  monthlyPayoutData: undefined,
  rankingsData: undefined,
  accountID: undefined,
  userToken: undefined,
  simulationHolding: undefined,
  simulationHistoryData: undefined,
  simulationGraphData: undefined,
  plansData: [],
  lastPage: '',
  activeDashboardTab: 0,
  simulationActiveTab: 0,
  drawerOpen: false,
  selectedSecurity: '', // '01256584',
  selectedPlan: 2
}))
