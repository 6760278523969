import { getUserToken } from '..'
import { useAppStore } from '../../storage'
import { _setBearer, getData, getUser } from '../api'

export const initializeApp = async (
  onFinishCallback?: () => void
): Promise<void> => {
  const userToken = getUserToken()

  if (userToken) {
    try {
      _setBearer()
      const user = (await getUser())?.data.data

      if (user) {
        useAppStore.setState({ userData: user })
        // localStorage.setItem('userToken', userToken)
        const data = await getData()
        if (data) {
          // useAppStore.setState({ user: data.user, userToken })
        }
      }
    } catch (error) {
      // TODO handle error
    }
    onFinishCallback && onFinishCallback()
  }
}
