import * as React from 'react';
import styles from './styles.module.scss';
import {
  Grid,
  type SelectChangeEvent,
  Typography,
  IconButton,
  Popover,
  FormControlLabel,
  RadioGroup,
  Radio,
  type CircularProgressProps,
  Box,
  CircularProgress,
  Button
} from '@mui/material';
import { type IUserData } from '../../types';
import CustomTable from '../../components/custom.table';
import CustomSelect from '../../components/custom.select';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAppStore } from '../../storage';
import toast from 'react-hot-toast';
import SimulationChart from '../../components/chart/SimulationChart';
import { simulateHolding } from '../../helpers/api';
import { useCallback } from 'react';

const HEADER_LABELS = ['Total Value', 'Profit/Loss', 'Simulation History'];
const HISTORY_TABLE_HEADERS = ['Sims Remaining', 'Stock', 'Put', 'Straddle', 'Total'];

interface IAccountTabProps {
  user: IUserData
}

const CircularProgressWithLabel = (
  props: CircularProgressProps & { simremains: number }
): JSX.Element => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        width: '80px',
        height: '80px'
      }}
      className={styles.circular_progress_wrapper}
    >
      <CircularProgress
        sx={{
          color: (theme) => 'rgba(55, 52, 219, 0.94)'
        }}
        size={80}
        variant="determinate"
        {...props}
      />
      <Box
        className={styles.circular_label_wrapper}
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" className={styles.remaining}>
          {props.simremains}
        </Typography>
        <Typography className={styles.progress} variant="caption" component="div">
          {`${Math.round(props.value ?? 0)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const SimulationTab: React.FC<IAccountTabProps> = ({ user }) => {
  const [graphData1, setGraphData1] = React.useState<any>(null);
  const [graphData2, setGraphData2] = React.useState<any>(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [simDifference, setSimDifference] = React.useState<any>(undefined);
  const [prevHolding, setPrevHolding] = React.useState<any>(undefined);
  const [securitiesSelectItem, setSecuritiesSelectItem] = React.useState<number>(0);
  const [tableCells1, setTableCells1] = React.useState<(JSX.Element | string | number)[][]>([]);

  const {
    simulationHolding,
    simulationHistoryData,
    simulationGraphData
  } = useAppStore();

  const remainingSimulations = simulationHolding?.simulations ?? 0;

  const handleSimData = useCallback((): void => {
    if (!simulationHolding || !simulationGraphData) return; // Exit if no data
    if (simulationHolding && prevHolding) {
      setSimDifference({
        stock: prevHolding.security.stock_price - simulationHolding.security.stock_price,
        put: prevHolding.security.put - simulationHolding.security.put,
        straddle: prevHolding.security.straddle - simulationHolding.security.straddle,
        total: prevHolding.security.total_price - simulationHolding.security.total_price,
        simsRemaining: (
          (simulationGraphData.sim_A.length * 100 * 100) /
          simulationHolding.security.initial_sim
        ).toFixed()
      });
      setActiveTab(0);
    }
  }, [simulationHolding, prevHolding, simulationGraphData]);

  const handleSimulate = (simAll?: boolean): void => {
    const simsNum = simAll ? simulationHolding?.security.simulations : 1;

    setPrevHolding(simulationHolding);
    const promise = simulateHolding(simulationHolding, simsNum, simAll ? 1 : 0);

    void toast.promise(promise, {
      loading: "Processing...",
      success: (response) => {
        if (!response) {
          return "Operation in progress";
        }
        handleSimData();
        return "Simulation completed successfully!";
      },
      error: (err) => {
        if (err.message === "Too Many Attempts.") {
          return "Too many simulation requests. Please wait a while and try again!";
        }
        return err.message || "An error occurred during simulation.";
      }
    });
  };

  const handleSimHistoryData = useCallback((): void => {
    if (simulationHistoryData?.data?.length > 0) {
      const cells: (JSX.Element | string | number)[][] = [];
      simulationHistoryData.data.forEach((sh: any) => {
        const listRow: (JSX.Element | string | number)[] = [];
        listRow.push(remainingSimulations);
        const stockDiff = sh.stock_price - simulationHolding.security.stock_price;
        const stockDiffColor = stockDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)';
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>
              ${sh.stock_price / 10000}
            </Typography>
            <Typography color={stockDiffColor} className={styles.value2}>
              {`${stockDiff > 0 ? '+' : '-'}$${Math.abs(stockDiff / 10000)}`}
            </Typography>
          </Grid>
        );

        const putDiff = sh.put - simulationHolding.security.put;
        const putDiffColor = putDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)';
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>${sh.put / 10000}</Typography>
            <Typography color={putDiffColor} className={styles.value2}>
              {`${putDiff > 0 ? '+' : '-'}$${Math.abs(putDiff / 10000)}`}
            </Typography>
          </Grid>
        );

        const straddleDiff = sh.straddle - simulationHolding.security.straddle;
        const straddleDiffColor = straddleDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)';
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>
              ${sh.straddle / 10000}
            </Typography>
            <Typography color={straddleDiffColor} className={styles.value2}>
              {`${straddleDiff > 0 ? '+' : '-'}$${Math.abs(
                straddleDiff / 10000
              )}`}
            </Typography>
          </Grid>
        );

        const totalDiff = sh.total_price - simulationHolding.security.total_price;
        const totalDiffColor = totalDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)';
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>
              ${sh.total_price / 10000}
            </Typography>
            <Typography color={totalDiffColor} className={styles.value2}>
              {`${totalDiff > 0 ? '+' : '-'}$${Math.abs(totalDiff / 10000)}`}
            </Typography>
          </Grid>
        );
        cells.push(listRow);
      });
      setTableCells1(cells);
    }
  }, [simulationHistoryData, simulationHolding, remainingSimulations]);

  const handleSimGraphData = useCallback((): void => {
    if (simulationGraphData) {
      const dataInvestor: number[] = [];
      const dataNvic: number[] = [];
      const arrayLabelsA: number[] = [];
      const dataInvestor2: number[] = [];
      const dataNvic2: number[] = [];
      const arrayLabelsB: number[] = [];

      // Process sim_A data
      if (simulationGraphData.sim_A?.length) {
        simulationGraphData.sim_A.forEach((item: any, index: number) => {
          dataInvestor.push(item.total_price / 10000);
          arrayLabelsA.push(index);

          // Calculate profit/loss if simulationHolding exists
          if (simulationHolding?.security?.last_total_price) {
            dataInvestor2.push(
              (item.total_price - simulationHolding.security.last_total_price) / 10000
            );
          }
        });
      }

      // Process sim_B data - FIXED TYPO IN .length
      if (simulationGraphData.sim_B?.length) {
        simulationGraphData.sim_B.forEach((item: any, index: number) => {
          const nvicValue = item.total_price / 10000;
          dataNvic.push(nvicValue);
          arrayLabelsB.push(index);

          // Calculate profit/loss if simulationHolding exists
          if (simulationHolding?.security?.last_total_price) {
            dataNvic2.push(
              nvicValue - (simulationHolding.security.last_total_price / 10000)
            );
          }
        });
      }

      setGraphData1({
        labels: arrayLabelsA,
        dataInvestor,
        dataNvic: dataNvic.slice(0, arrayLabelsA.length) // Ensure equal lengths
      });

      setGraphData2({
        labels: arrayLabelsB,
        dataInvestor: dataInvestor2,
        dataNvic: dataNvic2
      });
    } else {
      // Initialize empty datasets
      setGraphData1({
        labels: [],
        dataInvestor: [],
        dataNvic: []
      });
      setGraphData2({
        labels: [],
        dataInvestor: [],
        dataNvic: []
      });
    }
  }, [simulationGraphData, simulationHolding]);

  const handleInputChange = (e: SelectChangeEvent<unknown>): void => {
    setSecuritiesSelectItem(e.target.value as number);
  };

  const renderContent = (tab: number): JSX.Element | undefined => {
    if (
      (tab === 0 || tab === 1) &&
      (!graphData1?.labels?.length && !graphData2?.labels?.length)
    ) {
      return (
        <Typography color="error" variant="h6">
          No historical simulation data available.
        </Typography>
      );
    }

    if (tab === 2 && !tableCells1.length) {
      return (
        <Typography color="error" variant="h6">
          No simulation history records found.
        </Typography>
      );
    }

    switch (tab) {
      case 0:
        return <SimulationChart data={graphData1} />;
      case 1:
        return <SimulationChart data={graphData2} />;
      case 2:
        return <CustomTable headers={HISTORY_TABLE_HEADERS} cells={tableCells1} />;
      default:
        return (
          <Typography color="error" variant="h6">
            Invalid tab selection.
          </Typography>
        );
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleDotsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const variatonOpen = Boolean(anchorEl);
  const simulationDotsId = variatonOpen ? 'simple-popover' : undefined;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(parseFloat((event.target as HTMLInputElement).value));
    setActiveTab(parseFloat((event.target as HTMLInputElement).value));
  };

  const renderColorStat = (value: number): JSX.Element => {
    const value2Parsed = `${value > 0 ? '+' : '-'}` + '$' + Math.abs(value);
    const color = value > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)';
    return (
      <Typography color={color} className={styles.value2}>
        {value2Parsed}
      </Typography>
    );
  };

  const handleSimulateClick = (): void => {
    if (simulationHolding) {
      if (simulationHolding.simulations > 0) {
        handleSimulate(false);
      } else {
        toast.error('No available simulations');
      }
    } else {
      toast.error('Holding is not chosen');
    }
  };

  const handleSimulateAllClick = (): void => {
    if (simulationHolding) {
      if (simulationHolding.simulations > 0) {
        handleSimulate(true);
      } else {
        toast.error('No available simulations');
      }
    } else {
      toast.error('Holding is not chosen');
    }
  };

  React.useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      setPrevHolding({ ...simulationHolding });
      handleSimData();
    } else {
      handleSimData();
    }
  }, [simulationHolding, pageLoaded, handleSimData]);

  React.useEffect(() => {
    handleSimHistoryData();
  }, [simulationHistoryData, simulationHolding, handleSimHistoryData]);

  React.useEffect(() => {
    handleSimGraphData();
  }, [simulationGraphData, simulationHolding, handleSimGraphData]);

  return (
    <Grid
      display={'grid'}
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      className={styles.account_tab_wrapper}
    >
      <Grid className={styles.header_1024}>
        <Grid className={styles.header_labels}>
          <Typography className={styles.title}>
            {/* {HEADER_LABELS[activeTab]} */}
            {/* {'Sec#' + selectedSecurity} */}
          </Typography>
          <div className={styles.sims}>
            <Typography className={styles.sims_remaining}>
              Sims Remaining
            </Typography>
            <Typography className={styles.sims_value}>{remainingSimulations}</Typography>
          </div>
        </Grid>

        <Grid className={styles.header_buttons}>
          <IconButton
            aria-describedby={simulationDotsId}
            className={styles.dots_button}
            onClick={handleDotsClick}
          >
            <MoreHorizIcon className={styles.dots_icon} />
          </IconButton>
          <Popover
            id={simulationDotsId}
            open={variatonOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Grid className={styles.radio_buttons_wrapper}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  className={styles.radio_option}
                  value={0}
                  control={<Radio color="success" />}
                  label="Total value"
                />
                <FormControlLabel
                  className={styles.radio_option}
                  value={1}
                  control={<Radio color="success" />}
                  label="Profit/Loss"
                />
                <FormControlLabel
                  className={styles.radio_option}
                  value={2}
                  control={<Radio color="success" />}
                  label="Table"
                />
              </RadioGroup>
            </Grid>
          </Popover>
        </Grid>
      </Grid>
      <Grid gridColumn="span 9" className={styles.securities}>
        <div className={styles.securities_wrapper}>
          <Grid className={styles.header}>
            <Typography className={styles.title}>
              {HEADER_LABELS[activeTab]}
            </Typography>

            <Grid className={styles.header_buttons}>
              <CustomSelect
                value={securitiesSelectItem}
                defaulValue={'Last Year'}
                list={['Last Year', 'This Year']}
                onChangeCallback={handleInputChange}
              />
              <IconButton
                aria-describedby={simulationDotsId}
                className={styles.dots_button}
                onClick={handleDotsClick}
              >
                <MoreHorizIcon className={styles.dots_icon} />
              </IconButton>
              <Popover
                id={simulationDotsId}
                open={variatonOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Grid className={styles.radio_buttons_wrapper}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className={styles.radio_option}
                      value={0}
                      control={<Radio color="success" />}
                      label="Total value"
                    />
                    <FormControlLabel
                      className={styles.radio_option}
                      value={1}
                      control={<Radio color="success" />}
                      label="Profit/Loss"
                    />
                    <FormControlLabel
                      className={styles.radio_option}
                      value={2}
                      control={<Radio color="success" />}
                      label="Table"
                    />
                  </RadioGroup>
                </Grid>
              </Popover>
            </Grid>
          </Grid>
          <Grid className={styles.body}>{renderContent(activeTab)}</Grid>
        </div>
      </Grid>
      <Grid gridColumn="span 3" className={styles.rankings_wrapper}>
        {simulationHolding ? (
          simDifference ? (
            // Render full chart and buttons when simulationHolding is available
            <div className={styles.rankings}>
              <Grid className={styles.header}>
                <Typography className={styles.title}>Run Simulation</Typography>
                <span className={styles.security_label}>
                  Security
                  <span className={styles.security_number}>
                    {' '}#{simulationHolding.security?.security_number || 'N/A'}
                  </span>
                </span>
              </Grid>
              <div className={styles.stats}>
                <div className={styles.stat}>
                  <div className={styles.pie1}>
                    <img src="/assets/images/IconPie.svg" />
                  </div>
                  <div className={styles.stat_title}>Stock</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>
                      {'$' + simulationHolding.security.stock_price / 10000}
                    </Typography>
                    {renderColorStat(simDifference.stock / 10000)}
                  </div>
                </div>
                <div className={styles.stat}>
                  <div className={styles.pie1}>
                    <img src="/assets/images/IconPie.svg" />
                  </div>
                  <div className={styles.stat_title}>Put</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>
                      {'$' + simulationHolding.security.put / 10000}
                    </Typography>
                    {renderColorStat(simDifference.put / 10000)}
                  </div>
                </div>
                <div className={styles.stat}>
                  <div className={styles.pie1}>
                    <img src="/assets/images/IconPie.svg" />
                  </div>
                  <div className={styles.stat_title}>Straddle</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>
                      {'$' + simulationHolding.security.straddle / 10000}
                    </Typography>
                    {renderColorStat(simDifference.straddle / 10000)}
                  </div>
                </div>
                <div className={`${styles.stat} ${styles.total_stat}`}>
                  <div className={`${styles.pie1} ${styles.pie1_1024}`}>
                    <img src="/assets/images/pie_2.svg" />
                  </div>
                  <div className={styles.stat_title}>Total</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>
                      {'$' + simulationHolding.security.total_price / 10000}
                    </Typography>
                    {renderColorStat(simDifference.total / 10000)}
                  </div>
                </div>
              </div>
              <div className={styles.simulations_remaining}>
                <CircularProgressWithLabel
                  value={(remainingSimulations / simulationHolding?.security?.initial_sim) * 100}
                  simremains={remainingSimulations}
                />
                <div className={styles.chart_legend}>
                  <div className={styles.color}></div>
                  <Typography className={styles.label4}>
                    Simulations <br></br> Remaining
                  </Typography>
                </div>
              </div>
              <div className={styles.buttons}>
                <Button
                  className={styles.simulate_button}
                  startIcon={
                    <img
                      className={styles.simulate_button_icon}
                      src="/assets/images/Arrow.svg"
                    />
                  }
                  disabled={remainingSimulations <= 0}
                  onClick={handleSimulateClick}
                >
                  Simulate
                </Button>
                <Button
                  className={`${styles.simulate_button}`}
                  startIcon={
                    <img
                      className={styles.simulate_button_icon}
                      src="/assets/images/Arrow.svg"
                    />
                  }
                  onClick={handleSimulateAllClick}
                  disabled={remainingSimulations <= 0}
                >
                  Simulate All
                </Button>
              </div>
            </div>
          ) : (
            // Render empty chart format and buttons when simulationHolding is not available but simulationHolding is present
            <div className={styles.rankings}>
              <Grid className={styles.header}>
                <Typography className={styles.title}>Run Simulation</Typography>
                <span className={styles.security_label}>
                  Security
                  <span className={styles.security_number}>
                    {' '}#{simulationHolding.security_number}
                  </span>
                </span>
              </Grid>
              <div className={styles.stats}>
                <div className={styles.stat}>
                  <div className={styles.pie1}>
                    <img src="/assets/images/IconPie.svg" />
                  </div>
                  <div className={styles.stat_title}>Stock</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>$0</Typography>
                    {renderColorStat(0)}
                  </div>
                </div>
                <div className={styles.stat}>
                  <div className={styles.pie1}>
                    <img src="/assets/images/IconPie.svg" />
                  </div>
                  <div className={styles.stat_title}>Put</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>$0</Typography>
                    {renderColorStat(0)}
                  </div>
                </div>
                <div className={styles.stat}>
                  <div className={styles.pie1}>
                    <img src="/assets/images/IconPie.svg" />
                  </div>
                  <div className={styles.stat_title}>Straddle</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>$0</Typography>
                    {renderColorStat(0)}
                  </div>
                </div>
                <div className={`${styles.stat} ${styles.total_stat}`}>
                  <div className={`${styles.pie1} ${styles.pie1_1024}`}>
                    <img src="/assets/images/pie_2.svg" />
                  </div>
                  <div className={styles.stat_title}>Total</div>
                  <div className={styles.stat_values}>
                    <Typography className={styles.value1}>$0</Typography>
                    {renderColorStat(0)}
                  </div>
                </div>
              </div>
              <div className={styles.simulations_remaining}>
                <CircularProgressWithLabel
                  value={(remainingSimulations / simulationHolding?.security?.initial_sim) * 100}
                  simremains={remainingSimulations}
                />
                <div className={styles.chart_legend}>
                  <div className={styles.color}></div>
                  <Typography className={styles.label4}>
                    Simulations <br></br> Remaining
                  </Typography>
                </div>
              </div>
              <div className={styles.buttons}>
                <Button
                  className={`${styles.simulate_button}`}
                  startIcon={
                    <img
                      className={styles.simulate_button_icon}
                      src="/assets/images/Arrow.svg"
                    />
                  }
                  onClick={handleSimulateClick}
                >
                  Simulate
                </Button>
                <Button
                  className={`${styles.simulate_button}`}
                  startIcon={
                    <img
                      className={styles.simulate_button_icon}
                      src="/assets/images/Arrow.svg"
                    />
                  }
                  onClick={handleSimulateAllClick}
                >
                  Simulate All
                </Button>
              </div>
            </div>
          )
        ) : (
          <div className={styles.rankings}>
            <Grid className={styles.header}>
              <Typography className={styles.title}>Run Simulation</Typography>
              <span className={styles.security_label}>
                Security
                <span className={styles.security_number}>
                  {' '}#N/A
                </span>
              </span>
            </Grid>
            <div className={styles.stats}>
              <div className={styles.stat}>
                <div className={styles.pie1}>
                  <img src="/assets/images/IconPie.svg" />
                </div>
                <div className={styles.stat_title}>Stock</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {'$' + 0}
                  </Typography>
                  {renderColorStat(0)}
                </div>
              </div>
              <div className={styles.stat}>
                <div className={styles.pie1}>
                  <img src="/assets/images/IconPie.svg" />
                </div>
                <div className={styles.stat_title}>Put</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {'$' + 0}
                  </Typography>
                  {renderColorStat(0)}
                </div>
              </div>
              <div className={styles.stat}>
                <div className={styles.pie1}>
                  <img src="/assets/images/IconPie.svg" />
                </div>
                <div className={styles.stat_title}>Straddle</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {'$' + 0}
                  </Typography>
                  {renderColorStat(0)}
                </div>
              </div>
              <div className={`${styles.stat} ${styles.total_stat}`}>
                <div className={`${styles.pie1} ${styles.pie1_1024}`}>
                  <img src="/assets/images/pie_2.svg" />
                </div>
                <div className={styles.stat_title}>Total</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {'$' + 0}
                  </Typography>
                  {renderColorStat(0)}
                </div>
              </div>
            </div>
          </div>
        )}
      </Grid>
    </Grid >
  );
};

export default SimulationTab;
